import * as React from "react"
import PropTypes from "prop-types"

import GridRowAutoHeight from "./GridRowAutoHeight"
import ArchiveProject from "./ArchiveProject"
import formatTypography from "../utils/formatTypography"

const ArchiveYear = ({ data }) => {
  if (!data) return null

  const grid = {
    horizontal: "b",
    vertical: "a",
  }

  return (
    <div className="flex flex-wrap relative">
      <div className="w-full lg:w-3/11">
        <GridRowAutoHeight horizontal={grid.horizontal}>
          <h3 className="text-4xl leading-none font-bold">
            {formatTypography(data.primary.year.text)}
          </h3>
        </GridRowAutoHeight>
      </div>
      <ul className="w-full lg:w-8/11">
        {data.items.map((item, index) => {
          return (
            <li key={`item-${index}`}>
              <ArchiveProject item={item} grid={grid} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ArchiveYear.propTypes = {
  data: PropTypes.object,
}

export default ArchiveYear
