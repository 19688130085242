import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import useSiteData from "../hooks/useSiteData"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Grid from "../components/Grid"
import GridRowAutoHeight from "../components/GridRowAutoHeight"
import TextContent from "../components/TextContent"
import EmailLink from "../components/EmailLink"
import PhoneLink from "../components/PhoneLink"
import PageTitle from "../components/PageTitle"

const ContactPage = ({ data }) => {
  if (!data) return null
  const { prismicContactPage } = data
  const { title, body, show_contact_details, info } = prismicContactPage.data

  const { email, phone } = useSiteData()

  const grid = {
    horizontal: "b",
    vertical: "a",
  }

  // Prepare SEO metadata
  const metaTitle = title.text ? title.text : null
  const metaDescription = body.raw ? body.raw[0].text : null

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDescription} />
      <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
        <div className="py-row lg:pt-0">
          <PageTitle title={title} />
          <div className="xl:-mt-row">
            <div className="flex flex-wrap justify-center">
              <div className="w-10/12 lg:w-11/24">
                <div className="w-full lg:w-7/11 text-base leading-snug lg:text-lg lg:leading-snug">
                  <GridRowAutoHeight horizontal={grid.horizontal}>
                    <div className="pb-4">
                      <TextContent>
                        <RichText
                          render={body.raw}
                          htmlSerializer={htmlSerializer}
                        />
                        {show_contact_details && email.text && (
                          <div>
                            <p>
                              <EmailLink email={email.text} />
                            </p>
                          </div>
                        )}
                        {show_contact_details && phone.text && (
                          <div>
                            <p>
                              <PhoneLink phone={phone.text} />
                            </p>
                          </div>
                        )}
                      </TextContent>
                    </div>
                  </GridRowAutoHeight>
                </div>
              </div>
              <div className="w-10/12 lg:w-11/24">
                <div className="w-full lg:w-5/11 text-sm leading-snug">
                  <GridRowAutoHeight horizontal={grid.horizontal}>
                    <div className="pb-4">
                      <TextContent>
                        <RichText
                          render={info.raw}
                          htmlSerializer={htmlSerializer}
                        />
                      </TextContent>
                    </div>
                  </GridRowAutoHeight>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query ContactPage {
    prismicContactPage {
      _previewable
      data {
        title {
          text
        }
        body {
          raw
        }
        show_contact_details
        info {
          raw
        }
      }
    }
  }
`

export default withPrismicPreview(ContactPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
