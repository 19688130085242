import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

import GridRowAutoHeight from "./GridRowAutoHeight"
import ArchiveYear from "./ArchiveYear"
import formatTypography from "../utils/formatTypography"

const Archive = () => {
  const staticData = useStaticQuery(query)
  const { data } = useMergePrismicPreviewData(staticData)
  const { prismicArchive } = data
  const { title, body } = prismicArchive.data

  const grid = {
    horizontal: "b",
    vertical: "a",
  }

  return (
    <div className="flex flex-wrap justify-center mt-row">
      <div className="w-10/12 lg:w-11/24 mb-row lg:mb-0">
        {title.text && (
          <GridRowAutoHeight horizontal={grid.horizontal}>
            <h2 className="font-bold text-2xl leading-snug w-full lg:w-4/11 lg:pr-4">
              {formatTypography(title.text)}
            </h2>
          </GridRowAutoHeight>
        )}
      </div>
      <ul className="w-10/12 lg:w-11/24">
        {body.map((slice, index) => {
          if (slice.slice_type === "year") {
            return (
              <li key={`year-${index}`}>
                <GridRowAutoHeight horizontal={grid.horizontal}>
                  <ArchiveYear data={slice} />
                </GridRowAutoHeight>
              </li>
            )
          }
          return null
        })}
      </ul>
    </div>
  )
}

export const query = graphql`
  query Archive {
    prismicArchive {
      _previewable
      data {
        title {
          text
        }
        body {
          ... on PrismicArchiveDataBodyYear {
            slice_type
            primary {
              year {
                text
              }
            }
            items {
              project_title {
                text
              }
              project_description {
                raw
              }
              project_link {
                url
              }
              project_image {
                alt
                dimensions {
                  width
                  height
                }
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default Archive
