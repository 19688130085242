import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"

import IndexPage from "./index"
import ProjectPage from "./{PrismicProject.url}"
import AboutPage from "./about"
import ContactPage from "./contact"

import Layout from "../components/Layout"
import Grid from "../components/Grid"
import GridRowAutoHeight from "../components/GridRowAutoHeight"
import TextContent from "../components/TextContent"
import PageTitle from "../components/PageTitle"

const ErrorPage = ({ data }) => {
  if (!data) return null
  const { prismicErrorPage } = data
  const { title, body } = prismicErrorPage.data

  const grid = {
    horizontal: "b",
    vertical: "a",
  }

  return (
    <Layout>
      <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
        <GridRowAutoHeight horizontal={grid.horizontal}>
          <div className="py-row lg:pt-0 min-h-50vh">
            <PageTitle title={title} />
            <div className="xl:-mt-row">
              <div className="flex flex-wrap justify-center">
                <div className="w-10/12 lg:w-22/24">
                  <div className="w-full lg:w-7/22 text-base leading-snug lg:text-lg lg:leading-snug">
                    <GridRowAutoHeight horizontal={grid.horizontal}>
                      <div className="pb-4">
                        <TextContent>
                          <RichText
                            render={body.raw}
                            htmlSerializer={htmlSerializer}
                          />
                        </TextContent>
                      </div>
                    </GridRowAutoHeight>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridRowAutoHeight>
      </Grid>
    </Layout>
  )
}

ErrorPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query ErrorPage {
    prismicErrorPage {
      _previewable
      data {
        title {
          text
        }
        body {
          raw
        }
      }
    }
  }
`

export default withPrismicUnpublishedPreview(ErrorPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      projects_page: IndexPage,
      project: ProjectPage,
      about_page: AboutPage,
      contact_page: ContactPage,
    }),
  },
])
