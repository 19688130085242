import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import GridRowAutoHeight from "./GridRowAutoHeight"
import ArchiveProjectText from "./ArchiveProjectText"
import getAssetFormat from "../utils/getAssetFormat"

const ArchiveProject = ({ item, grid }) => {
  if (!item) return null

  const {
    project_title: title,
    project_description: description,
    project_link: link,
    project_image: image,
  } = item

  // Get image format
  const format = image.dimensions ? getAssetFormat(image.dimensions) : null
  // Calculate grid height and width classes based on image format
  let heightClasses = "h-row-4 sm:h-row-5 lg:h-row-3"
  if (format === "landscape") {
    heightClasses = "h-row-3 sm:h-row-4 lg:h-row-2"
  } else if (format === "portrait") {
    heightClasses = "h-row-5 sm:h-row-7 lg:h-row-4"
  }
  // Define sizes attribute for img tags
  const sizes =
    "(min-width: 640px) 41.666666666666667vw, (min-width: 1024px) 20.833333333333333vw, 33.333333333333333vw"

  // Show/hide image on text hover
  const [imageIsVisible, setImageIsVisible] = React.useState(false)
  const imageVisibilityClasses = imageIsVisible
    ? "lg:opacity-100"
    : "lg:opacity-0"
  function showImage() {
    setImageIsVisible(true)
  }
  function hideImage() {
    setImageIsVisible(false)
  }

  return (
    <div className="text-xs sm:text-sm mb-row lg:mb-leading-snug flex">
      <div
        className={`w-2/5 sm:w-1/2 lg:absolute lg:-left-6/11 lg:top-0 lg:w-5/11 transition-opacity ${imageVisibilityClasses}`}
      >
        {image.gatsbyImageData && (
          <div className={`w-full ${heightClasses}`}>
            <GatsbyImage
              alt={image.alt ? image.alt : ""}
              image={image.gatsbyImageData}
              className="object-cover h-full w-full"
              sizes={sizes}
            />
          </div>
        )}
      </div>
      <div
        className="w-3/5 sm:w-1/2 pl-2 sm:pl-4 lg:w-full lg:pl-0"
        onMouseOver={showImage}
        onMouseLeave={hideImage}
      >
        <div className="lg:hidden">
          <GridRowAutoHeight horizontal={grid.horizontal}>
            <ArchiveProjectText
              title={title}
              description={description}
              link={link}
            />
          </GridRowAutoHeight>
        </div>
        <div className="hidden lg:block">
          <ArchiveProjectText
            title={title}
            description={description}
            link={link}
          />
        </div>
      </div>
    </div>
  )
}

ArchiveProject.propTypes = {
  item: PropTypes.object,
  grid: PropTypes.object,
}

export default ArchiveProject
