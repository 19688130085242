import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

import * as styles from "./ProjectsPageLink.module.css"
import GridRowAutoHeight from "./GridRowAutoHeight"
import formatTypography from "../utils/formatTypography"

const ProjectsPageLink = ({ project, indexIsOdd, format, grid, index }) => {
  // Destructure project object
  const { title, introduction, featured_image: image } = project.document.data
  // Calculate grid height/width classes and sizes attribute based on image format
  let widthClasses = "w-11/12 sm:w-8/12 lg:w-9/11 xl:w-7/11"
  let sizes =
    "(min-width: 640px) 66.666666666666667vw, (min-width: 1024px) 75vw, (min-width: 1280px) 58.333333333333333vw, 91.666666666666667vw"
  if (format === "landscape") {
    widthClasses = "w-full sm:w-9/12 lg:w-14/22 xl:w-12/22"
    sizes =
      "(min-width: 640px) 75vw, (min-width: 1024px) 58.333333333333333vw, (min-width: 1280px) 50vw, 100vw"
  } else if (format === "portrait") {
    widthClasses = "w-10/12 sm:w-7/12 lg:w-9/11 xl:w-7/11"
    sizes =
      "(min-width: 640px) 58.333333333333333vw, (min-width: 1024px) 75vw, (min-width: 1280px) 58.333333333333333vw, 83.333333333333333vw"
  }
  let heightClasses = "h-row-5 lg:h-row-3 xl:h-row-2"
  if (format === "landscape") {
    heightClasses = "h-row-4 lg:h-row-3"
  } else if (format === "portrait") {
    heightClasses = "h-row-6 lg:h-row-4 xl:h-row-3"
  }
  // Set eager loading for first images
  const loading = index < 3 ? "eager" : "lazy"

  return (
    <Link to={project.url} className={`block ${widthClasses} ${styles.link}`}>
      <div
        className={`w-full ${heightClasses} overflow-hidden bg-black bg-opacity-10`}
      >
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ? image.alt : ""}
          className={`object-cover h-full w-full ${styles.linkImage}`}
          loading={loading}
          sizes={sizes}
        />
      </div>
      <GridRowAutoHeight horizontal={grid.horizontal}>
        <div
          className={`pt-2 pr-2 pb-4 ${
            !indexIsOdd || format === "landscape" ? "pl-2" : ""
          } ${indexIsOdd && format === "landscape" ? "sm:pl-0" : ""} lg:pl-0`}
        >
          <h2 className={styles.linkText}>{formatTypography(title.text)}</h2>
          <p>{formatTypography(introduction.text)}</p>
        </div>
      </GridRowAutoHeight>
    </Link>
  )
}

ProjectsPageLink.propTypes = {
  project: PropTypes.object,
  indexIsOdd: PropTypes.bool,
  format: PropTypes.string,
  widthClasses: PropTypes.string,
  heightClasses: PropTypes.string,
  grid: PropTypes.object,
  index: PropTypes.number,
}

export default ProjectsPageLink
