import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { GatsbyImage } from "gatsby-plugin-image"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Grid from "../components/Grid"
import GridRowAutoHeight from "../components/GridRowAutoHeight"
import TextContent from "../components/TextContent"
import PageTitle from "../components/PageTitle"
import getAssetFormat from "../utils/getAssetFormat"
import Archive from "../components/Archive"

const AboutPage = ({ data }) => {
  if (!data) return null
  const { prismicAboutPage } = data
  const { title, body, image } = prismicAboutPage.data

  const grid = {
    horizontal: "b",
    vertical: "a",
  }

  // Get image format
  const format = image.dimensions ? getAssetFormat(image.dimensions) : null
  // Calculate grid height and width classes based on image format
  let widthClasses = "w-10/12 lg:w-8/9"
  if (format === "landscape") {
    widthClasses = "w-full sm:w-10/12 lg:w-full"
  } else if (format === "portrait") {
    widthClasses = "w-10/12 sm:w-8/12 lg:w-7/9"
  }
  let heightClasses = "h-row-8 sm:h-row-12 lg:h-row-5"
  if (format === "landscape") {
    heightClasses = "h-row-7 sm:h-row-9 lg:h-row-4"
  } else if (format === "portrait") {
    heightClasses = "h-row-12 sm:h-row-14 lg:h-row-6"
  }

  // Prepare SEO metadata
  const metaTitle = title.text ? title.text : null
  const metaDescription = body.raw ? body.raw[0].text : null
  const metaImage = image.url ? image.url : null

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDescription} image={metaImage} />
      <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
        <div className="py-row lg:pt-0">
          <PageTitle title={title} />
          <div className="xl:-mt-row">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-11/24">
                <div className="w-full lg:w-9/11 flex justify-center sm:justify-start">
                  {image.alt && (
                    <div
                      className={`mb-row lg:mb-0 sm:ml-1/12 lg:ml-0 ${widthClasses} ${heightClasses} bg-black bg-opacity-10`}
                    >
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt={image.alt ? image.alt : ""}
                        className="object-cover h-full w-full"
                        loading="eager"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="w-10/12 lg:w-11/24">
                <div className="w-full sm:w-4/5 lg:w-9/11 xl:w-8/11">
                  <GridRowAutoHeight horizontal={grid.horizontal}>
                    <TextContent>
                      <RichText
                        render={body.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </TextContent>
                  </GridRowAutoHeight>
                </div>
              </div>
            </div>
            <Archive />
          </div>
        </div>
      </Grid>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query AboutPage {
    prismicAboutPage {
      _previewable
      data {
        title {
          text
        }
        body {
          raw
        }
        image {
          gatsbyImageData
          alt
          url
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`

export default withPrismicPreview(AboutPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
