import * as React from "react"
import { linkResolver } from "../utils/linkResolver"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Grid from "../components/Grid"
import ProjectsPageLink from "../components/ProjectsPageLink"
import getAssetFormat from "../utils/getAssetFormat"
import useProjectsPageData from "../hooks/useProjectsPageData"

const IndexPage = () => {
  const { title, projects } = useProjectsPageData()

  const grid = {
    horizontal: "a",
    vertical: "a",
  }

  return (
    <Layout>
      <SEO titleTemplate="%s • Works in Clay" />
      {title.text && <h1 className="sr-only">{title.text}</h1>}
      <Grid horizontal={grid.horizontal} vertical={grid.vertical}>
        <div className="flex justify-center">
          <ul className="py-row lg:py-0 w-full lg:w-22/24 flex flex-wrap">
            {projects.map((featuredProject, index) => {
              const { project } = featuredProject
              // Check if project is assigned correctly
              if (!project.document || project.isBroken) return null
              // Destructure project object
              const { featured_image: image } = project.document.data
              // Check if image exists
              if (image.dimensions) {
                // Calculate image format based on image ratio
                const format = getAssetFormat(image.dimensions)
                // Check if even or odd index
                const indexIsOdd = index % 2 !== 0

                return (
                  <li
                    key={`project-${index}`}
                    className={`flex ${
                      indexIsOdd ? "flex-row-reverse" : ""
                    } lg:justify-center w-full ${
                      format === "landscape" ? "lg:w-full" : "lg:w-11/22"
                    }`}
                  >
                    <ProjectsPageLink
                      project={project}
                      indexIsOdd={indexIsOdd}
                      format={format}
                      grid={grid}
                      index={index}
                    />
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </Grid>
    </Layout>
  )
}

export default withPrismicPreview(IndexPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
