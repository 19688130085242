import * as React from "react"
import * as styles from "./ArchiveProjectText.module.css"
import { RichText } from "prismic-reactjs"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import htmlSerializer from "../utils/htmlSerializer"

import TextContent from "./TextContent"

const ArchiveProjectText = ({ title, description, link }) => {
  return (
    <TextContent>
      {title.text && <h4>{title.text}</h4>}
      <RichText render={description.raw} htmlSerializer={htmlSerializer} />
      {link.url && (
        <p className={styles.link}>
          <Link to={`${link.url}`}>View project</Link>
        </p>
      )}
    </TextContent>
  )
}

ArchiveProjectText.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  link: PropTypes.object,
}

export default ArchiveProjectText
